

















import { Component, Vue } from 'vue-property-decorator';

    @Component
export default class Notification extends Vue {
    private msg: string = '';

    private duration: number = 4000;

    private isShow: boolean = false;

    private timer: any = null;

    private startTimer(): void {
        this.timer = setTimeout(() => {
            this.clearTimer();
            this.handleClose();
        }, this.duration);
    }

    private handleClose(): void {
        this.isShow = false;
    }

    private clearTimer() {
        clearTimeout(this.timer);
    }

    private created() {
        const notification = document.getElementById('notification');

        if (notification) {
            document.body.removeChild(notification);
        }
    }

    private mounted() {
        this.isShow = true;
        this.startTimer();
    }

    private doDestroy() {
        this.$destroy();
    }
}





















import { Component, Prop, Vue, Model } from 'vue-property-decorator';

@Component
export default class AppInput extends Vue {
    @Model('input')
    private value!: string;

    @Prop({ default: '' })
    private placeholder!: string;

    @Prop({ type: Boolean, default: false })
    private error!: boolean;

    @Prop({ default: 'black' })
    private theme!: 'black' | 'grey';

    @Prop({ default: 'text' })
    private type!: 'text' | 'number';

    @Prop({ default: 0 })
    private maxLength!: number;

    private handleInput(e: any): void {
        const value = e.target.value;

        if (this.maxLength > 0 && value.length > this.maxLength) {
            e.target.value = this.value;

            return;
        }

        this.$emit('input', value);
    }

    private isFocused: boolean = false;

    private onFocus(): void {
        this.isFocused = true;

        this.$emit('focus');
    }

    private onBlur(): void {
        this.isFocused = false;

        this.$emit('blur');
    }
}





































import { Component, Vue, Emit, Model } from 'vue-property-decorator';
import AppInput from '@/components/controls/AppInput.vue';
import AppButton from '@/components/controls/AppButton.vue';
import { EMAIL_REGEXP } from '@/consts/regexp';
import Notification from '@/components/Notification/notify';
import { UserModule } from '@/store/modules/user';

@Component({
    components: { AppButton, AppInput }
})
export default class GetinModal extends Vue {
    @Model('close')
    private readonly isShown!: boolean;

    @Emit('close')
    private closeModal(): false {
        return false;
    }

    private email: string = '';
    private isEmailSent: boolean = false;

    private async submitEmail(): Promise<void> {
        if (!EMAIL_REGEXP.test(this.email)) {
            Notification({
                msg: this.$t('emailError'),
            });

            return;
        }

        const res = await UserModule.login(this.email);

        if (res.status === 200) {
            this.email = '';
            this.isEmailSent = true;
        } else {
            Notification({
                msg: this.$t('retryAfter', { count: res.data.retryAfter }),
            });
        }
    }
}

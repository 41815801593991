


























































import { Vue, Prop, Component } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import GetinModal from '@/components/GetinModal.vue';
import niknames from '@/consts/niknames';
import eventBus from '@/utils/eventBus';
import SignoutModal from '@/components/SignoutModal.vue';
import { language, LanguageModule } from '@/store/modules/language';
import { LANGUAGE_NAMES } from '@/consts/common';

@Component({
    components: { SignoutModal, GetinModal },
})
export default class Header extends Vue {
    @Prop({ type: Boolean, default: false })
    private light!: boolean;

    @Prop({ type: Boolean, default: false })
    private hideNikname!: boolean;

    private isGetinShown: boolean = false;

    private isSignoutShown: boolean = false;

    private niknameIndex: number = 0;

    private get canIGoBack(): boolean {
        return this.$route.path !== '/';
    }

    private get elseLangs(): language[] {
        return LanguageModule.locales.filter(item => item !== this.currentLanguage);
    }

    private get niknames(): string[] {
        return niknames[LanguageModule.currentLanguage];
    }

    private get currentLanguage(): language {
        return LanguageModule.currentLanguage;
    }

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    private get email(): string {
        return UserModule.email;
    }

    private created(): void {
        eventBus.on('pleaseAuth', () => {
            this.showGetinModal();
        });
    }

    private mounted(): void {
        this.niknameIndex = Math.floor(Math.random() * Math.floor(this.niknames.length));
    }

    private changeLang(lang: language): void {
        LanguageModule.changeAppLanguage(lang);
    }

    private showGetinModal(): void {
        this.isGetinShown = true;
    }

    private showSignoutModal(): void {
        this.isSignoutShown = true;
    }

    private goBack(): void {
        this.$router.push({ name: 'BattlesListPage' });
    }

    private getLangName(lang: language): string {
        return LANGUAGE_NAMES[lang];
    }
}

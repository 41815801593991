

























import { Component, Emit, Model, Vue } from 'vue-property-decorator';
import AppButton from '@/components/controls/AppButton.vue';
import { UserModule } from '@/store/modules/user';

@Component({
    components: { AppButton }
})
export default class SignoutModal extends Vue {
    @Model('close')
    private readonly isShown!: boolean;

    @Emit('close')
    private closeModal(): false {
        return false;
    }

    private signout(): void {
        UserModule.signout();
    }
}










import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class AppButton extends Vue {
    @Prop({ default: false, type: Boolean })
    private disabled!: boolean;

    @Prop({ default: '100%' })
    private width!: string;

    @Prop({ default: 'primary' })
    private type!: 'primary' | 'primaryDark' | 'dark';

    private handleClick(): void {
        if (this.disabled) {
            return;
        }

        this.$emit('click');
    }
}

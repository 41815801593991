const niknames = {
    'ru-RU': [
        'крипто воин',
        'крипто маг',
        'чемпион',
    ],
    'en-US': [
        'crypto warrior',
        'crypto wizard',
        'champion',
    ],
    'zh-CN': [
        '加密暂时',
        '加密向导',
        '冠军',
    ],
};

export default niknames;
